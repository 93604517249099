import { useQuery } from "@apollo/client"
import { Box, Grid } from "@chakra-ui/layout"
import gql from "graphql-tag"
import { useEffect, useState } from "react"
import Photomodel from "./Photomodel"

const Photo_Query = gql(`
    query Photo{
        photos{
            caption,url,_id
        }
    }
`)

const PhotoList = ()=>{
    const {data,loading,error} = useQuery(Photo_Query)
    const [photos, setphotos] = useState<Array<{url:string,caption:string,_id:string}>>([])
    useEffect(() => {
        if(!loading&&data){
            console.log(data)
            setphotos(data.photos)
        }
    }, [data,loading,error]);

    const reset = (id:string)=>{
        const netdataset = photos.filter(item=>item._id!==id)
        setphotos(netdataset)
    }
    const extacttowebp = (filename :string)=>{
        const newfilename = filename.split('.jpg')
        return newfilename[0]+'-min.webp'
    }
    const items = photos.map(item=>{
        return <Photomodel key={item._id} reset={reset} caption={item.caption} _id={item._id} url={extacttowebp(item.url)} />
    })
    return <Box>
        <Grid templateColumns={["repeat(2,1fr)","repeat(2,1fr)","repeat(3,1fr)","repeat(3,1fr)"]}>
            {items}
        </Grid>
    </Box>
}

export {}
export default PhotoList