import { Box, Center, Grid, Heading, Stack, Text } from "@chakra-ui/layout"
import { useRecoilState } from "recoil"
import AddAward from "../components/AddAward"
import AddPicture from "../components/AddPicture"
import {AddProject} from "../components/AddProject"
import userdataState from "../recoil/userdata"


const Home = ()=>{
    const [userdata] = useRecoilState(userdataState)
    return (
        <Center mb={10} mt={10}>
        <Box w={["95%","90%","80%","70%"]}>
            <Heading bg="white" p={10} rounded="2xl" boxShadow="md">Hi {userdata?.username}</Heading>
            <Grid templateColumns={["repeat(1, 1fr)","repeat(1, 1fr)","repeat(1, 1fr)","repeat(2, 1fr)"]} gap={5}  w="100%" mt={5}>
            <Box rounded="md" boxShadow="md" w={"100%"}  bg="white">
                    <AddAward/>
                </Box>
                <Box rounded="md" boxShadow="md"  w={"100%"}   bg="white">
                    <AddProject/>
                </Box>
                <Box rounded="md" boxShadow="md"   w={"100%"}  bg="white">
                    <AddPicture/>
                </Box>
            </Grid>
            {/* <Stack flexWrap="wrap" alignItems="flex-start" mt={10} w="100%" display="flex" flexDirection="row" spacing={0}>
            <Box rounded="md" boxShadow="md" w={["100%","100%","calc(50% - 10px)","calc(50% - 10px)"]}  bg="white">
                    <AddAward/>
                </Box>
                <Box rounded="md" boxShadow="md" w={["100%","100%","calc(50% - 10px)","calc(50% - 10px)"]}  bg="white">
                    <AddProject/>
                </Box>
                <Box rounded="md" boxShadow="md"  w={["100%","100%","calc(50% - 10px)","calc(50% - 10px)"]}  bg="white">
                    <AddPicture/>
                </Box>
            </Stack> */}
        </Box>
        </Center>
    )
}
export default Home