import { useMutation } from "@apollo/client"
import { Box, Button, FormControl, FormLabel, Heading, Input, Text } from "@chakra-ui/react"
import gql from "graphql-tag"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useDropzone } from "react-dropzone"

const Upload_Mutation = gql(`
    mutation AddPhoto($file:Upload!,$caption:String!){
        addphoto(caption:$caption,file:$file){
            url
        }
    }
`)

const AddPicture = ()=>{
    const [file, setfile] = useState<null | File>(null)
    const [img,setimg] = useState<null | ArrayBuffer | string>(null)
    const [caption, setcaption] = useState<string>("")
    const captionRef = useRef<HTMLInputElement | null>(null)
    const [addphoto,{data,loading,error}] = useMutation(Upload_Mutation)
    const onDrop = useCallback(
        (accfile) => {
            setfile(accfile[0])
        },
        [],
    )
    const {getInputProps,getRootProps,isDragActive} = useDropzone({onDrop})

    useEffect(() => {
        const getBase64 = (file :File)=>{
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = ()=>{
                setimg(reader.result)
            }
            reader.onerror = (err)=>{
                console.log(err)
            }
        }
        if(file){
            getBase64(file)
        }
    }, [file]);

    const uploadimg = ()=>{
        if(!caption || caption === ""){
            return captionRef.current?.focus()
        }
        if(!file){
            return null
        }
        addphoto({variables:{
            caption:caption,
            file: file 
        }})

    }
    useEffect(() => {
        if(data && !loading){
            setimg(null)
            setfile(null)
            setcaption("")
        }
        if(error){
            console.log(error)
        }
    }, [data,loading,error]);
    return(
        <Box p={5}>
        <Heading>Add Picture</Heading>
        <hr/>
        <Box d="flex" justifyContent="center" alignItems="center" mt="10px" h="150px" bg={file ? `url('${img}')` :"blue.400"} bgSize="cover" bgPosition="center" {...getRootProps()} rounded="2xl">
            <input {...getInputProps()}/>
            {
               isDragActive ? <Text color="white" >Drop file here</Text> : <Text color="white">Click or Drag file to upload</Text> 
            }
        </Box>
        <FormControl mt={5}>
            <FormLabel>Caption</FormLabel>
            <Input type="text" ref={captionRef} placeholder="caption" required value={caption} onChange={(e)=>setcaption(e.target.value)} />
        </FormControl>
        <Button type="button" w="100%" colorScheme="blue" onClick={()=>{uploadimg()}} mt={5}>Upload</Button>
    </Box>
    )
}
export default AddPicture