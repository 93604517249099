import { gql, useMutation } from "@apollo/client"
import { Button } from "@chakra-ui/button"
import { Box } from "@chakra-ui/layout"
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useDisclosure } from "@chakra-ui/react"
import React, { useRef, useState } from "react"
import { useEffect } from "react"
const deletepic_mutation = gql(`
    mutation Deletephoto($id:String!){
        deletephoto(id:$id){
            _id
        }
    }
`)
const Photomodel:React.FC<{url:string,caption:string,_id:string,reset:Function}> = ({url,caption,_id,reset })=>{
    const boxref = useRef<null|HTMLDivElement>(null)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [deletepicmu,{data,loading,error}] = useMutation(deletepic_mutation)
    const deletepic = (id:string)=>{
        deletepicmu({variables:{id:id}})
    }
    useEffect(() => {
        if(data&&!loading){
            reset(_id)
        }
    }, [data,loading,error]);
    return<>
    
    <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{caption}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <img src={`https://storage.googleapis.com/thistinestorage/minpic/${url}`} />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button onClick={()=>deletepic(_id)} colorScheme="red">Delete</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      
    <Box w="100%" h={["sm","sm","sm","md"]} ref={boxref} display="flex" onClick={onOpen} _hover={{cursor:"pointer"}} justifyContent="center" alignItems="center"
     bg={`url('https://storage.googleapis.com/thistinestorage/minpic/${url}')`} bgSize="cover" bgPosition="center"
     
    >
    </Box>
    </>
}
// export {}

export default Photomodel