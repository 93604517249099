import { Box } from "@chakra-ui/layout"
import { Center } from "@chakra-ui/react"
import React from "react"
import AddAward from "../components/AddAward"
import AwardList from "../components/AwardsList"

const Awards = ()=>{
    return <Center mb={10} mt={10}><Box w={["95%","90%","80%","70%"]}>
    <Box bg="white" rounded="md" boxShadow="md" >
        <AddAward/>
    </Box>
    <Box bg="white"  mt={5} padding={3} rounded="md">
    <AwardList/>
    </Box>
    </Box>
    
    </Center>
}

export default Awards