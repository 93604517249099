import { Button } from "@chakra-ui/button"
import { FormControl, FormLabel } from "@chakra-ui/form-control"
import { Input } from "@chakra-ui/input"
import { Box, Heading, HStack } from "@chakra-ui/layout"
import { Select } from "@chakra-ui/select"
import { Textarea } from "@chakra-ui/textarea"
import {Text} from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import gql from "graphql-tag"
import { useMutation } from "@apollo/client"


type FormData = {
    name: String,
    year: String,
    description: String,
    type: String,
    language: String,
    framework: String,
    facebook: String,
    instagram: String,
    youtube: String,
    website: String,
    googleplay: String,
    github: String
}

const AddProject_Mutation = gql(`
   mutation AddProject(
        $name: String!,
        $year: String!,
        $description: String!,
        $type: String!,
        $language: String,
        $framework: String,
        $facebook: String,
        $instagram: String,
        $youtube: String,
        $website: String,
        $googleplay: String,
        $github: String
    ){
        addprojects(
            name: $name,
            year: $year,
            description: $description,
            type: $type,
            language: $language,
            framework: $framework,
            facebook: $facebook,
            instagram: $instagram,
            youtube: $youtube,
            website: $website,
            googleplay: $googleplay,
            github: $github
        ){
            name
        }
    }
`)


export const AddProject = ()=>{
    const { register , handleSubmit,reset  } = useForm<FormData>();
    const [link,setlink] = useState<Array<any>>([])
    const selectref = useRef<null | HTMLSelectElement>(null)
    const inputref = useRef<null | HTMLInputElement>(null)
    const [linkvalue, setlinkvalue] = useState<string>("")
    const [addnewproject,{loading,data}] = useMutation(AddProject_Mutation)
    const addphoto = (data :FormData)=>{
        const getlink = (type:string)=>{
            const newlink = link.find(item=>item.type === type)
            if(newlink){
                return newlink.link
            }
            return null
        }
        const vari = {
                name: data.name,
                year: data.year,
                description: data.description,
                type: data.type,
                language: data.language,
                framework: data.framework,
                facebook: getlink("facebook"),
                instagram: getlink("instagram"),
                youtube: getlink("youtube"),
                website: getlink("website"),
                googleplay: getlink("googleplay"),
                github: getlink("github"),

                
        }
        addnewproject({variables:vari})
    }
    useEffect(()=>{
        if(!loading && data){
            reset()
            setlink([])
        }
    },[data,loading])
    const supportlink = ['facebook','instragram','youtube','googleplay','github','website']
    const deletelink= (data:any)=>{
        const newlink = link.filter(e=>{
            if(e.type === data.type && e.link === data.link){
                return false
            }
            return true
        })
        setlink(newlink)
    }
    return(
        <Box p={5}>
            <Heading>Add Project</Heading>
            <hr/>
            <form style={{marginTop:"10px"}} onSubmit={handleSubmit(addphoto)}>
                <FormControl>
                    <FormLabel>Name</FormLabel>
                    <Input placeholder="Name" autoComplete="off" {...register("name",{required:true})}/>
                </FormControl>
                <FormControl>
                    <FormLabel>Description</FormLabel>
                    <Textarea {...register("description",{required:true})} placeholder="Description"></Textarea>
                </FormControl>
                <FormControl>
                    <FormLabel>Type</FormLabel>
                    <Input placeholder="Type" autoComplete="off" {...register("type",{required:true})}/>
                </FormControl>
                <FormControl>
                    <FormLabel>Year</FormLabel>
                    <Input placeholder="Year" autoComplete="off" {...register("year",{required:true})}/>
                </FormControl>
                <FormControl>
                    <FormLabel>Language</FormLabel>
                    <Input placeholder="Language" autoComplete="off" {...register("language")}/>
                </FormControl>
                <FormControl>
                    <FormLabel>Framework</FormLabel>
                    <Input placeholder="Framework" autoComplete="off" {...register("framework")}/>
                </FormControl>
                <FormControl>
                    <FormLabel>Link</FormLabel>
                    <HStack>
                    <Input w="70%" value={linkvalue} ref={inputref} onChange={(e)=>{setlinkvalue(e.target.value)}} placeholder="https://......" />
                    <Select w="20%" ref={selectref}>
                        {supportlink.map(item=>{
                        if(!link.find(data=>data.type === item)){
                        return <option key={item} value={item}>{item}</option>}
                    }
                        )}
                    </Select>
                    <Button w="10%" type="button" onClick={()=>{
                        if(!linkvalue || linkvalue === ""){
                            return inputref?.current?.focus()
                        }
                        setlink([...link,{type:selectref?.current?.value,link:linkvalue}])
                        setlinkvalue("")
                    }}>add</Button>
                    </HStack>
                </FormControl>
                {link.map((item)=>
                    <Box mt={2} key={item.type} p={2} bg="teal" rounded="md">
                        <HStack justifyContent="space-between">
                        <Text color="white" w="70%" overflow="auto">{item.link}</Text>
                        <Text color="white">{item.type}</Text>
                        <Button type="button" onClick={()=>deletelink(item)} colorScheme="red"> X </Button>
                        </HStack>
                    </Box>
                )}
                <Button colorScheme="blue" mt={5} w="100%" type="submit">Add</Button>
            </form>
        </Box>
    )
}
// export {}

// export default AddProject