import { useQuery } from "@apollo/client"
import { Table, Tbody, Th, Thead, Tr } from "@chakra-ui/table"
import gql from "graphql-tag"
import { useEffect, useState } from "react"
import Awardmodel from "./Awardmodel"
const Award_Query = gql(`
    query Awards{
        awards{
            _id,
            name,
            place,
            year,
            location
        }
    }
`)
const AwardList = ()=>{
    const [awards, setawards] = useState<Array<{_id:string,name:string,place:string,year:string,location:string}>>([])
    const {data,loading,error} = useQuery(Award_Query)
    useEffect(() => {
        if(data && !loading){
            setawards(data.awards)
        }
    }, [data,loading,error]);
    const reset = (id:string)=>{
        const newawardset = awards.filter(item=>item._id !== id)
        setawards(newawardset)
    }
    return <Table >
        <Thead>
            <Tr>
                <Th>Name</Th>
                <Th>Action</Th>
            </Tr>

        </Thead>
        <Tbody>
            {awards.map(item=><Awardmodel key={item._id} _id={item._id} name={item.name} location={item.location} place={item.place} reset={reset} year={item.year} />
        )}
        </Tbody>
    </Table>
}

export default AwardList