import { gql, useMutation } from "@apollo/client"
import { Button } from "@chakra-ui/button"
import { FormControl, FormLabel } from "@chakra-ui/form-control"
import { Input } from "@chakra-ui/input"
import { Box, Heading } from "@chakra-ui/layout"
import { useEffect} from 'react'
import { useForm } from "react-hook-form"

type FormData = {
    name: String,
    location: String,
    year: String,
    place:String,
}

const AddAward_Mutation = gql(`
    mutation AddAward($name:String!,$location:String!,$year:String!,$place:String!){
        addAward(name:$name,location:$location,year:$year,place:$place){
            _id
        }
    }
`)

const AddAward = ()=>{
    const { register ,  setValue, handleSubmit,reset  } = useForm<FormData>();
    const [addAward,{data,loading}] = useMutation(AddAward_Mutation)
    const addaward = (data:FormData)=>{
        addAward({variables: {name:data.name,year:data.year,location:data.location,place:data.place}})
    }
    useEffect(()=>{
        if(!loading && data){
            reset()
        }
    },[loading,data])
    return(
        <Box p={5}>
            <Heading>Add Award</Heading>
            <hr/>
            <form style={{marginTop:"10px"}} onSubmit={handleSubmit(addaward)}>
                <FormControl>
                    <FormLabel>Name</FormLabel>
                    <Input placeholder="Name" autoComplete="off" {...register("name",{required:true})}/>
                </FormControl>
                <FormControl>
                    <FormLabel>Location</FormLabel>
                    <Input placeholder="Location" autoComplete="off" {...register("location",{required:true})}/>
                </FormControl>
                <FormControl>
                    <FormLabel>Place</FormLabel>
                    <Input placeholder="Place" autoComplete="off" {...register("place",{required:true})}/>
                </FormControl>
                <FormControl>
                    <FormLabel>Year</FormLabel>
                    <Input placeholder="Year" autoComplete="off" {...register("year",{required:true})}/>
                </FormControl>
                <Button colorScheme="blue" mt={5} w="100%" type="submit">Add</Button>
            </form>
        </Box>
    )
}
// export {}
export default AddAward