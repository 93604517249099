import { Center, Box } from "@chakra-ui/react"
import React from "react"
import AddPicture from "../components/AddPicture"
import PhotoList from "../components/PhotoList"

const Photos = ()=>{
    return         <Center mb={10} mt={10}>
    <Box w={["95%","90%","80%","70%"]}>
    <Box bg="white" rounded="md" boxShadow="md" >
        <AddPicture/>
    </Box>
    <Box mt={5}>
        <PhotoList/>
    </Box>
    </Box></Center>
}
export default Photos