import React, { useEffect } from 'react'
import {Box, Heading, Center, Stack, Button, useDisclosure, IconButton,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    VStack,
} from '@chakra-ui/react'
import { NavLink } from 'react-router-dom'
import gql from 'graphql-tag'
import { useLazyQuery } from '@apollo/client'
import {HamburgerIcon} from '@chakra-ui/icons'

const Logout = gql(`
    query logout{
        logout{
            _id
        }
    }
`)
const NavBar = ()=>{
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [getlogout,{data,loading}] = useLazyQuery(Logout)
    // const btnRef = React.useRef()
    const logout =()=>{
        getlogout()

    }
    useEffect(() => {
        if(!loading&&data){
        window.location.reload()
    }
    }, [loading,data])
    return(
        <>
         <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}

      >
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Menu</DrawerHeader>

            <DrawerBody>
                
            <VStack>
            <NavLink className="buttonlink" onClick={onClose} to="/" >Home</NavLink>
            <NavLink className="buttonlink" onClick={onClose} to="/photos" >Pictures</NavLink>
            <NavLink className="buttonlink" onClick={onClose}  to="/awards" >Awards</NavLink>
            <NavLink className="buttonlink" onClick={onClose} to="/projects" >Projects</NavLink>
            </VStack>



            </DrawerBody>
            <DrawerFooter>
            <Button colorScheme="red" onClick={()=>{logout()}}>Logout</Button>
            </DrawerFooter>


          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
        <Box w="100%" bg="white">
        <Center>
        <Box w={["90%","90%","90%","70%"]} >
            <Stack alignItems="center" flexDirection={"row"} spacing="none" padding={2} justifyContent={"space-between"}>
            <Heading>Thistine</Heading>
            <IconButton aria-label="open" icon={<HamburgerIcon />} colorScheme="gray" d={['block','block','block','none']} onClick={onOpen}> Open </IconButton>
            <Box d={["none","none","none","flex"]} alignItems="center">
            <NavLink className="buttonlink" to="/" >Home</NavLink>
            <NavLink className="buttonlink"  to="/photos" >Pictures</NavLink>
            <NavLink className="buttonlink"  to="/awards" >Awards</NavLink>
            <NavLink className="buttonlink"  to="/projects" >Projects</NavLink>
            <Button colorScheme="red" size="md" onClick={()=>{logout()}}>Logout</Button>
            {/* <button className="buttonlink">logout</button> */}
            </Box>
            </Stack>
            
        </Box>
        </Center>
        <hr/>
        </Box>
        </>
    )
}

export default NavBar