import { useMutation } from "@apollo/client"
import { Button } from "@chakra-ui/button"
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useDisclosure, Text } from "@chakra-ui/react"
import { Td, Tr } from "@chakra-ui/table"
import gql from "graphql-tag"
import React, { useEffect } from "react"
const deleteaward_Mutation = gql(`
    mutation DeleteAward($id:String!){
        deleteaward(id:$id){
            _id 
        }
    }
`)
const Awardmodel: React.FC<{_id:string,name:string,place:string,year:string,location:string,reset:Function}> = ({name,place,year,location,reset,_id})=>{
    const [deleteaward,{data,loading,error}] = useMutation(deleteaward_Mutation)
    const { isOpen, onOpen, onClose } = useDisclosure()
    useEffect(() => {
        if(data && !loading){
            reset(_id)
        }
        if(error){
            console.log(error)
        }
    }, [data,loading,error]);
    return<>
          <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Place: {place} </Text>
            <Text>year: {year} </Text>
            <Text>location: {location} </Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button onClick={()=>{deleteaward({variables:{id:_id} }) 
            onClose()
        }} colorScheme="red">Delete</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    <Tr>
        <Td>{name}</Td>
        <Td><Button colorScheme="red" onClick={onOpen}>Delete</Button></Td>
    </Tr>
    </>
}

export {}

export default Awardmodel