import { useQuery } from "@apollo/client"
import { Table, Tbody, Th, Thead } from "@chakra-ui/table"
import gql from "graphql-tag"
import { useEffect, useState } from "react"
import ProjectModel from "./ProjectModel"
const Project_Query = gql(`
    query Project{
        projects{
            _id,
            name,
            description
        }
    }
`)
const ProjectList = () =>{
    const {data,loading,error} = useQuery(Project_Query)
    const [projects, setprojects] = useState<Array<{_id:string,name:string,description:string}>>([])
    useEffect(() => {
       if(data && !loading){
           setprojects(data.projects)
       } 
    }, [data,loading,error]);
    const reset = (id:string)=>{
        const newprojectlist = projects.filter(item=>item._id !== id)
        setprojects(newprojectlist)
    }
    return <Table>
        <Thead>
            <Th>Name</Th>
            <Th>Action</Th>
        </Thead>
        <Tbody>
            {projects.map(item=><ProjectModel reset={reset} name={item.name} _id={item._id} description={item.description} />
                )}
            
        </Tbody>
    </Table>
}

export default ProjectList
