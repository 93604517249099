import { Button } from "@chakra-ui/button"
import { FormControl, FormLabel } from "@chakra-ui/form-control"
import { Input } from "@chakra-ui/input"
import { useForm } from "react-hook-form";
import { Box, Heading, HStack } from "@chakra-ui/layout"
import {gql,useLazyQuery} from '@apollo/client'
import { useToast } from "@chakra-ui/toast";
import {useEffect} from 'react'
import { useRecoilState } from "recoil";
import isinitdataState from "../recoil/isinitdata";
type FormData = {
    email: string;
    password: string;
  };

const login_query = gql`
query Login($email:String!,$pwd:String!){
    login(email:$email,pwd:$pwd){
    username
  }}
`

const Login = ()=>{
    const toast = useToast()
    const { register , handleSubmit, formState: { errors },reset  } = useForm<FormData>();
    const [getlogin,{loading,data,error}] = useLazyQuery(login_query)
    const [_,setinitdata] = useRecoilState(isinitdataState)
    const login = (fdata : FormData)=>{
        getlogin({variables: {email:fdata.email,pwd:fdata.password}})

        
    }

    useEffect(() => {
        if(!loading && data){
            toast({
                position:"top",
                title:"Yeah !!",
                description: 'login success',
                status:"success",
                duration:1000,
                isClosable:true
            })
            setinitdata(true)
            reset()
        }
    }, [loading,data])

    useEffect(() => {
        if(error?.message){
        toast({
            position:"top",
            title:"error",
            description: error.message,
            status:"error",
            duration:4000,
            isClosable:true
        })}
    }, [error]);
    
    return(
        <HStack height="100vh" spacing="0">
            <Box w={["0%","0%","40%","70%"]} h="100%" background="url('https://storage.googleapis.com/thistinestorage/minpic/DSC_7066-Edit-2-min.webp')" backgroundPosition="center">
                
            </Box>
            <Box w={["100%","100%","60%","35%"]}   margin="0" h="100%" bg="#F4F4F4" padding={10}>

                
                <Box bg="white" padding={10} boxShadow="xl" borderRadius={10}>
                <Heading mb={10}>Login</Heading>
                <form onSubmit={handleSubmit(login)}>
                <FormControl id="email" isInvalid={errors.email !== null && errors.email !== undefined}  mb={5} isRequired>
                <FormLabel>Email</FormLabel>
                <Input {...register("email",{required:true})} autoComplete="off" placeholder="Email" />
                </FormControl>

                <FormControl id="pwd" mb={5} isInvalid={errors.password !== null && errors.password !== undefined} isRequired>
                <FormLabel>Password</FormLabel>
                <Input {...register("password",{required:true})} type="password" autoComplete="off" placeholder="password" />
                </FormControl>
                <Button isLoading={loading}  loadingText="logging in" colorScheme="teal" type="submit" w={['100%','100%','fit-content','fit-content']} >Login</Button>
                
                </form>

                </Box>
            </Box>
        </HStack>
    )
}

export default Login