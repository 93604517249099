import React,{useEffect} from 'react';
import './App.css';
import {BrowserRouter,Switch,Route, Redirect } from 'react-router-dom';
import NavBar from './components/Nav'
import {ChakraProvider} from '@chakra-ui/react'
import Login from './pages/Login';
import { gql,useLazyQuery } from '@apollo/client';
import { useRecoilState } from 'recoil';
import userdataState from './recoil/userdata';
import isinitdataState from './recoil/isinitdata';
import Home from './pages/Home';
import Photos from './pages/photos';
import Awards from './pages/Award';
import Projects from './pages/Project';


const verifyuser = gql(`
    query VerifyUser{
        verify{
            username,
            _id,
          }
    }
`)

function App() {
  const [getverify,{data,error,loading}] = useLazyQuery(verifyuser)
  const [userdata,setuserdata] = useRecoilState(userdataState)
  const [isinitdata,setisinitdata] = useRecoilState(isinitdataState)
  // const [pathname,setpathname] = useState<null | String>(null)
  useEffect(()=>{
    if(isinitdata === true){
      getverify()
      
    }
  },[isinitdata])

  useEffect(() => {
    if(!loading && data){
      setuserdata(data.verify)
      setisinitdata(false)
    }
    if(error){
      console.log(error)
      setisinitdata(false)
      setuserdata('no user')
    }
  }, [data,loading,error])

  useEffect(() => {
    setisinitdata(true)
  }, [])

 

  return (
  
  
    <ChakraProvider>
  <BrowserRouter>
  {userdata && userdata !== "no user" && userdata !== "initing" && <NavBar/>}
  
  <Switch>
    
    <Route path="/" exact>
    {userdata === "no user" && <Redirect to="/login"/> }
    {userdata && userdata !== "initing" && userdata !== "no user" && <Home/>}
    </Route>
    <Route path="/projects" exact>
    {userdata === "no user" && <Redirect to="/login"/> }
    {userdata && userdata !== "initing" && userdata !== "no user" && <Projects/>}
    </Route>
    <Route path="/photos" exact>
    {userdata === "no user" && <Redirect to="/login"/> }
    {userdata && userdata !== "initing" && userdata !== "no user" && <Photos/>}
    </Route>
    <Route path="/awards" exact>
    {userdata === "no user" && <Redirect to="/login"/> }
    {userdata && userdata !== "initing" && userdata !== "no user" && <Awards/>}
    </Route>
    <Route path="/login" exact>
    {userdata === "no user" && <Login/>}
    {userdata && userdata !== "initing" && userdata !== "no user" && <Redirect to="/"/>}
      
      
    </Route>
  </Switch>
  </BrowserRouter>
  </ChakraProvider>

  );
}

export default App;
