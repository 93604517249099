import { Center, Box } from "@chakra-ui/react"
import React from "react"
import { AddProject } from "../components/AddProject"
import ProjectList from "../components/ProjectList"

const Projects = ()=>{
    return <Center mb={10} mt={10}><Box w={["95%","90%","80%","70%"]}>
        <Box bg="white" rounded="md" boxShadow="md" >
            <AddProject/> 
        </Box>
        <Box bg="white"  mt={5} padding={3} rounded="md">

            <ProjectList  />
        </Box>
       
    </Box></Center>
}

export default Projects