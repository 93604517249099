import { useMutation } from "@apollo/client"
import { Button } from "@chakra-ui/button"
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useDisclosure, Text } from "@chakra-ui/react"
import { Td, Tr } from "@chakra-ui/table"
import gql from "graphql-tag"
import React, { useEffect } from "react"
const DeleteProject_Mutation = gql(`
    mutation DeleteProject($id:String!){
        deleteproject(id:$id){
            _id
        }
    }
`)
const ProjectModel:React.FC<{_id:string,name:string,description:string,reset:Function}> = ({_id,name,description,reset})=>{
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [deleteproject,{data,loading,error}] = useMutation(DeleteProject_Mutation)
    useEffect(() => {
        if(data && !loading){
            reset(_id)
        }
    }, [data,loading,error]);
    return<>
              <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>{description}</Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button onClick={()=>{deleteproject({variables:{id:_id} }) 
            onClose()
        }} colorScheme="red">Delete</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    <Tr>
        <Td>{name}</Td>
        <Td><Button colorScheme="red" onClick={onOpen}>Delete</Button></Td>
    </Tr>
    </>
}

export {}

export default ProjectModel