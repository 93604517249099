import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RecoilRoot } from 'recoil';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';

const client = new ApolloClient({
  link: createUploadLink({
    uri:"https://api.thistine.com/graphql",
    fetch,
    fetchOptions: {
      origin:'http://api.thistine.com/graphql',
      credentials: 'include' 
    }
  }),
  cache: new InMemoryCache()
  
});
ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
    <RecoilRoot>
    <App />
    </RecoilRoot>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
